import { LanguageContext } from '../hook/context'
import { useContext } from 'react'
import { MaterialSymbolsPlayArrowRounded } from '../data/svgIcons/all-svg-icons'
import clsx from 'clsx'
import { useState } from 'react'

const Video = () => {
  const [isPlayable, setIsPlayable] = useState({
    colorization: true,
    archive: true,
  })

  const { t } = useContext(LanguageContext)

  const playColorization = () => {
    const video = document.getElementById('colorization') as HTMLVideoElement
    video.play()
    setIsPlayable(prev => ({ ...prev, colorization: false }))
  }
  const playArchive = () => {
    const video = document.getElementById('archive') as HTMLVideoElement
    video.play()
    setIsPlayable(prev => ({ ...prev, archive: false }))
  }
  return (
    <div className='flex w-full divide-y divide-neutral-300/20 flex-col font-medium'>
      <section className='my-14'>
        <div className='flex flex-col lg:flex-row '>
          <div className='flex flex-col justify-center '>
            {t.video.titles[0]}

            {t.video.descriptions[0]}
          </div>
          <div className='relative flex justify-center items-center rounded-xl md:mt-5 mt-5 sm:mt-0 lg:ml-12'>
            <div className='absolute flex justify-center items-center mx-auto w-full h-full '>
              <button
                onClick={() => playArchive()}
                className={clsx(
                  isPlayable.archive ? false : 'hidden',
                  'max-w-[80px] max-h-[80px] flex justify-center items-center bg-black/10 hover:scale-125 transition-all z-10 text-neutral-900/90 backdrop-blur rounded-full shadow-lg',
                )}
              >
                <MaterialSymbolsPlayArrowRounded width={60} height={60} />
              </button>
            </div>
            <video
              id='archive'
              className='xl:max-w-[640px] border-4 border-neutral-200/20 object-cover lg:max-w-[500px] rounded-xl'
              poster='/assets/video/archive-placeholder.webp'
              preload='metadata'
              loop
            >
              <source src='/assets/video/web-archive.mp4' type='video/mp4' />
            </video>
          </div>
        </div>
      </section>
      <section className='pt-10 mb-10'>
        <div className='flex flex-col lg:flex-row  '>
          <div className='flex flex-col justify-center items-center'>
            {t.video.titles[1]}

            {t.video.descriptions[1]}
          </div>
          <div className='relative flex justify-center items-center rounded-xl md:mt-5 mt-5 sm:mt-0 lg:ml-12'>
            <div className='absolute flex justify-center items-center mx-auto w-full h-full '>
              <button
                onClick={() => playColorization()}
                className={clsx(
                  isPlayable.colorization ? false : 'hidden',
                  'w-[80px]h-[80px] relative hover:scale-125 transition-all bg-black/10 z-10 text-neutral-900/90 backdrop-blur rounded-full shadow-lg',
                )}
              >
                <MaterialSymbolsPlayArrowRounded width={60} height={60} />
              </button>
            </div>

            <video
              id='colorization'
              className='xl:max-w-[640px] border-4 border-neutral-200/20 object-cover lg:max-w-[500px] rounded-xl'
              preload='metadata'
              poster='/assets/video/colorization-placeholder.webp'
              loop
            >
              <source
                src='/assets/video/web-colorization.mp4'
                type='video/mp4'
              />
            </video>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Video
